<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <center>
            <div class="modal fade" id="costumModaldisclaimer" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
                aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
                style="margin: auto;">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header bg-info">
                            <h4 class="modal-title">Upload Data Excel</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-justify">
                            <p>
                            <div class="col-sm-12">
                                <div class="col-sm-12 text-center">
                                    <a :href="folder_excel + 'master_data.xlsx'" class="hitam">
                                        <button type="button" class="btn btn-success btn-sm text-white lebar2"
                                            data-toggle="tooltip" data-placement="left" title="Unduh master excel..">
                                            <i class="fas fa-download"></i> Unduh Master .xlsx
                                        </button>
                                    </a>
                                </div>
                                <!--IKON PETA 1-->
                                <div class="col-sm-12">
                                    <label>Pilih File<small class="text-info"> (*.xlsx)</small>
                                    </label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="far fa-file-image"></i></span>
                                        </div>
                                        <input type="file" class="form-control form-control-sm"
                                            id="fileToUploadfilenya_excel" name="fileToUploadfilenya_excel"
                                            placeholder="fileToUpload" style="font-size: smaller;"
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            @change="onChange">
                                        <input type="hidden" class="form-control form-control-sm" id="filenya_excel"
                                            name="filenya_excel" placeholder="File Excel">
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <label>Opsi</label>
                                    <div class="row">
                                        <div class="col-sm-9">
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="0"
                                                    aria-valuemin="0" aria-valuemax="100" style="width:0%"
                                                    id="persennyafilenya_excel">
                                                    <span id="tulisanpersenfilenya_excel">0%</span>
                                                </div>
                                            </div>
                                            <br>
                                            <span class="text-success" id="suksesnyafilenya_excel"></span>
                                        </div>
                                        <div class="col-sm-3 text-center" v-if="tempjumlah > 0">
                                            <button type="button" class="btn btn-warning btn-sm lebar text-white"
                                                @click="uploadsavedata()">
                                                Upload Data
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <!--IKON PETA 1 END-->
                                <div class="col-sm-12">
                                    Jumlah Data Import: {{ tempjumlah }}
                                    <div class="table-responsive table-wrapper" v-if="tempjumlah > 0">
                                        <table class="table m-0" id="tabeldata2">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        No
                                                    </th>
                                                    <template v-for="(dataheader, urutheader) in tempheader"
                                                        :key="urutheader">
                                                        <th>
                                                            {{ dataheader }}
                                                        </th>
                                                    </template>
                                                </tr>
                                            </thead>
                                            <template v-for="(datadata, urutdata) in tempdata" :key="urutdata">
                                                <tr v-if="urutdata > 0">
                                                    <td>{{ urutdata }}</td>
                                                    <td v-for="(isidata, urutisi) in datadata" :key="urutisi">
                                                        {{ isidata }}
                                                    </td>
                                                </tr>
                                            </template>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            </p>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-danger btn-sm lebar" data-dismiss="modal"><i
                                    class="fas fa-window-close"></i>
                                Tutup</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- /.modal -->
        </center>
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Rumah</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item active">Pengaturan Rumah</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih collapsed-card">
                                <div class="card-header border-transparent">
                                    <label>Filter Data</label>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Tahun Data</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="tahun"
                                                    name="tahun" placeholder="Tahun Data" v-model="tahun">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>No KTP </label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="ktp"
                                                    name="ktp" placeholder="No KTP" v-model="ktp">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nama Kepala Keluarga </label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="nama"
                                                    name="nama" placeholder="Nama Kepala Keluarga" v-model="nama">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Alamat</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="alamat"
                                                    name="alamat" placeholder="Alamat" v-model="alamat">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Kecamatan </label>
                                            <v-select :options="kecamatan" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedkecamatan"
                                                @update:modelValue="onkecamatanChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Kelurahan </label>
                                            <v-select :options="kelurahan" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedkelurahan"
                                                @update:modelValue="onkelurahanChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                        <div class="col-sm-6">
                                            <label>Pekerjaan</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="pekerjaan"
                                                    name="pekerjaan" placeholder="Pekerjaan" v-model="pekerjaan">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Klasifikasi Tipe Rumah </label>
                                            <v-select :options="tipe" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedtipe" @update:modelValue="ontipeChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Verifikasi </label>
                                            <v-select :options="verifikasi" :reduce="(label) => label.code"
                                                label="label" v-model="defaultSelectedverifikasi"
                                                @update:modelValue="onverifikasiChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Opsi </label><br>
                                            <button type="button" class="btn btn-warning btn-sm text-white lebar2"
                                                @click="caridata()" data-toggle="tooltip" data-placement="left"
                                                title="Cari data..">
                                                <i class="fas fa-search"></i> Cari / Filter
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <div class="col-sm-12 text-center p-2">
                                        <button type="button" class="btn btn-success3 btn-sm text-white lebar2"
                                            @click="prosesdata('Tambah')" data-toggle="tooltip" data-placement="left"
                                            title="Tambah data..">
                                            <i class="fas fa-plus"></i> Tambah
                                        </button>
                                        <button type="button" class="btn btn-success btn-sm text-white lebar2"
                                            @click="G_tableCSV('_DataRumah_')" data-toggle="tooltip"
                                            data-placement="left" title="Export data..">
                                            <i class="far fa-file-excel"></i> Export .xlsx
                                        </button>
                                        <button type="button" class="btn btn-warning btn-sm text-white lebar2"
                                            @click="bukamodal()" data-toggle="tooltip" data-placement="left"
                                            title="Upload data..">
                                            <i class="fas fa-upload"></i> Upload .xlsx
                                        </button>
                                    </div>
                                    <div class="col-sm-12 float-left pb-2 px-3">
                                        Jumlah Data: {{ G_numFormat(jumlahdata) }}
                                        <span style="float: right;">
                                            Halaman ke: <select v-model="halaman" @change="onChangeHalaman($event)">
                                                <option v-for="index in jumlahhalaman" :key="index">
                                                    {{ index }}</option>
                                            </select> dari {{ jumlahhalaman }}
                                        </span>
                                    </div>

                                </div>
                                <div class="card-body p-0">
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata">
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;">No</th>
                                                    <th style="width: 150px;">Opsi</th>
                                                    <th style="width: 50px;">Tahun<br>Data</th>
                                                    <th style="width: 100px;">Nama<br>KTP</th>
                                                    <th style="width: 150px;">Alamat</th>
                                                    <th style="width: 100px;">Pekerjaan</th>
                                                    <th style="width: 100px;">Klasifikasi</th>
                                                    <th style="width: 100px;">Verifikasi</th>
                                                    <th style="width: 100px;">Foto Rumah</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in datamaster" :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td>{{ urutlist + 1 }}</td>
                                                        <td class="text-center">
                                                            <button type="button"
                                                                class="btn btn-primary3 btn-sm text-white"
                                                                style="width: 100px;" @click="prosesdata(datalist.id)"
                                                                data-toggle="tooltip" data-placement="left"
                                                                title="Edit data.."><i class="fas fa-edit"></i>
                                                                Edit</button>
                                                            <button type="button"
                                                                class="btn btn-danger3 btn-sm text-white"
                                                                style="width: 100px;" @click="hapus(datalist.id)"
                                                                data-toggle="tooltip" data-placement="left"
                                                                title="Hapus data.."><i class="fas fa-trash-alt"></i>
                                                                Hapus</button>
                                                        </td>
                                                        <td>
                                                            {{ datalist.tahun }}
                                                        </td>
                                                        <td>
                                                            {{ datalist.nama }}<br>{{ datalist.ktp }}
                                                        </td>
                                                        <td>
                                                            {{ datalist.alamat }}, RT: {{ datalist.rt }}, RW: {{
                                                                datalist.rw }}, {{ datalist.namakelurahan }}, {{
                                                                datalist.namakecamatan }}
                                                        </td>
                                                        <td>
                                                            {{ datalist.pekerjaan }}
                                                        </td>
                                                        <td>
                                                            {{ datalist.namatipe }}
                                                        </td>
                                                        <td>
                                                            {{ datalist.namaverifikasi }}
                                                        </td>
                                                        <td class="text-center">
                                                            <img :src="folder_foto + datalist.filenya_rumah"
                                                                class="img-size-75 animation__wobble" alt="Rumah Image"
                                                                style="height: 100px;">
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </template>
                                            <tbody v-if="(datapesan == 'data kosong')">
                                                <tr>
                                                    <td colspan="9">Data masih kosong</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="card-footer p-3">
                                    <div class="col-sm-12 float-left pb-2 px-3">
                                        Jumlah Data: {{ G_numFormat(jumlahdata) }}
                                        <span style="float: right;">
                                            Halaman ke: <select v-model="halaman" @change="onChangeHalaman($event)">
                                                <option v-for="index in jumlahhalaman" :key="index">
                                                    {{ index }}</option>
                                            </select> dari {{ jumlahhalaman }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import xlsx from 'xlsx/dist/xlsx.full.min';
import readXlsxFile from 'read-excel-file'
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, vSelect, swal
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            datapesan: '',
            datamaster: [],
            carikata: '',
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            folder_foto: process.env.VUE_APP_URL_API + 'parastapainnovation_rumah-Gambar?f=',
            tahun: '',
            ktp: '',
            nama: '',
            alamat: '',
            pekerjaan: '',
            kecamatan: [],
            datakecamatan: [],
            defaultSelectedkecamatan: {
                code: 0,
                label: 'Semua Kecamatan',
                tag: '',
            },
            selectedkecamatan: '',
            kelurahan: [],
            datakelurahan: [],
            defaultSelectedkelurahan: {
                code: 0,
                label: 'Semua Kelurahan',
                tag: '',
            },
            selectedkelurahan: '',
            verifikasi: [],
            dataverifikasi: [],
            defaultSelectedverifikasi: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedverifikasi: '',
            tipe: [],
            datatipe: [],
            defaultSelectedtipe: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedtipe: '',

            tempheader: [],
            tempdata: [],
            tempjumlah: 0,
            folder_excel: process.env.VUE_APP_URL_API + 'parastapainnovation_data-EXCEL?f=',
        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        onChange(event) {
            if (event.target.files[0]['type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                this.file = event.target.files ? event.target.files[0] : null;
                if (this.file) {
                    this.tempheader = [];
                    this.tempdata = [];
                    readXlsxFile(this.file).then((rows) => {
                        // `rows` is an array of rows
                        // each row being an array of cells.
                        //alert(rows.length);
                        this.halamanloading = true;
                        this.tempjumlah = rows.length - 2;
                        for (let i = 0; i < rows.length; i++) {
                            this.halamanloading = true;
                            if (i == 0) {
                                var varheader = rows[i];
                                for (let x = 0; x < varheader.length; x++) {
                                    this.tempheader.push(varheader[x]);
                                }
                            }
                            else {
                                this.tempdata.push(rows[i]);
                            }
                            document.getElementById('persennyafilenya_excel').style.width = Math.round((i / (rows.length - 1)) * 100) + "%";
                            document.getElementById('tulisanpersenfilenya_excel').innerHTML = Math.round((i / (rows.length - 1)) * 100) + "%";
                        }
                        //console.log(this.tempdata);
                        this.halamanloading = false;
                    })
                }
            }
            else {
                swal.fire('Peringatan', 'File tidak sesuai format', 'error');
                return false;
            }
        },
        bukamodal() {
            $('#costumModaldisclaimer').modal('show');
        },
        async G_tableCSV(namefile) {
            this.ekportexcel = [];
            this.halamanloading = true;
            if (this.datamaster.length == 0) {
                this.ekportexcel.push({
                    "No": '1',
                    "Data": 'Data Tidak Diketemukan',
                });
            }
            else {
                for (let i = 0; i < this.datamaster.length; i++) {
                    this.ekportexcel.push({
                        "No": i + 1,
                        "Tahun": this.datamaster[i].tahun,
                        "Nama": this.datamaster[i].nama,
                        "KTP": this.datamaster[i].ktp,
                        "LATITUDE ": this.datamaster[i].latitude,
                        "LONGITUDE ": this.datamaster[i].longitude,

                    });
                }
            }

            function formatColumn(worksheet, col, fmt) {
                const range = XLSX.utils.decode_range(worksheet['!ref'])
                // note: range.s.r + 1 skips the header row
                for (let row = range.s.r + 1; row <= range.e.r; ++row) {
                    const ref = XLSX.utils.encode_cell({ r: row, c: col })
                    if (worksheet[ref] && worksheet[ref].t === 'n') {
                        worksheet[ref].z = fmt
                    }
                }
            }
            const currency = '0.00';
            const XLSX = xlsx;
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(this.ekportexcel);

            // ARRAY NUMBER SILAHKAN MASUKKAN NOMOR KOLOM
            for (let col of [0, 4, 5]) {
                formatColumn(worksheet, col, currency)
            }
            XLSX.utils.book_append_sheet(workbook, worksheet, "DataRumah");
            XLSX.writeFile(workbook, this.G_formatDate(Date(), 'd-M-Y') + namefile + ".xlsx");
            this.halamanloading = false;

        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        onverifikasiChange(a) {
            try {
                this.selectedverifikasi = this.verifikasi[a].tag;
            }
            catch (err) {
                return;
            }
        },
        ontipeChange(a) {
            try {
                this.selectedtipe = this.tipe[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onkecamatanChange(a) {
            try {
                this.selectedkecamatan = this.kecamatan[a].tag;
            }
            catch (err) {
                return;
            }
            this.AmbilDataDesa(this.selectedkecamatan);
        },
        onkelurahanChange(a) {
            try {
                this.selectedkelurahan = this.kelurahan[a].tag;
            }
            catch (err) {
                return;
            }
        },
        caridata() {
            this.ambildata();
        },
        uploadfile(idnya, tipenya) {
            var elmnt = document.getElementById("fileToUpload" + idnya);
            try {
                var namanya = elmnt.files[0].name;
                //alert(elmnt.files[0]['type'].toUpperCase());
                if (tipenya == 'image/*') {
                    if ((elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPEG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/PNG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/GIF')) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format gambar';
                        return;
                    }
                }
                else {
                    //alert(elmnt.files[0]['type']);
                    if (elmnt.files[0]['type'] != tipenya) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format xlsx';
                        return;
                    }
                }
            } catch (error) {
                document.getElementById('suksesnya' + idnya).innerHTML = '*pilih file terlebih dahulu';
                return;
            }
            var fd = new FormData();
            fd.append("fileToUpload", elmnt.files[0], elmnt.files[0].name);
            fd.append("folderToUpload", idnya);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation-FilesUpload", fd, {
                onUploadProgress: function (uploadEvent) {
                    document.getElementById('persennya' + idnya).style.width = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                    document.getElementById('tulisanpersen' + idnya).innerHTML = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                }
            }).then(
                function (res) {
                    document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                    if (res.data.filename != undefined) {
                        document.getElementById(idnya).value = res.data.filename;
                    }
                }
            ).catch(function (e) {
                document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                document.getElementById(idnya).value = '';
                console.log(e);
            }
            )
        },
        clearfilepeta(idnya) {
            if (document.getElementById(idnya).value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'File Tidak Tersedia',
                });
                return false;
            }

            var fd = new FormData();
            fd.append("folderToUpload", idnya);
            fd.append("filenya", document.getElementById(idnya).value);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation-FilesDeleted", fd).then(
                Response => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: Response.data.message,
                    });
                    document.getElementById(idnya).value == '';

                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )

            document.getElementById('fileToUpload' + idnya).value = '';
            document.getElementById(idnya).value = '';
            document.getElementById('persennya' + idnya).style.width = "0%";
            document.getElementById('tulisanpersen' + idnya).innerHTML = "0%";
            document.getElementById('suksesnya' + idnya).innerHTML = '';
            return false;
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            //alert(this.nama);
            var random = Math.random();
            await mainAPI.get("parastapainnovation_rumah-GetRumah?random=" + random + "&tahun=" + this.tahun
                + "&ktp=" + this.ktp + "&nama=" + this.nama + "&alamat=" + this.alamat + "&kecamatan=" + this.selectedkecamatan
                + "&kelurahan=" + this.selectedkelurahan + "&pekerjaan=" + this.pekerjaan + "&verifikasi=" + this.selectedverifikasi
                + "&tipe=" + this.selectedtipe
            ).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 20) + 1;
                        }
                        this.datamaster = Response.data.content.data;
                        this.halamanloading = false;
                    }
                    
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        pindahhalaman(halamannya) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            mainAPI.get("parastapainnovation_rumah-GetRumah?random=" + random + "&halaman=" + halamannya + "&tahun=" + this.tahun
                + "&ktp=" + this.ktp + "&nama=" + this.nama + "&alamat=" + this.alamat + "&kecamatan=" + this.selectedkecamatan
                + "&kelurahan=" + this.selectedkelurahan + "&pekerjaan=" + this.pekerjaan + "&verifikasi=" + this.selectedverifikasi
                + "&tipe=" + this.selectedtipe).then(
                    Response => {
                        this.datapesan = Response.data.message;
                        if (this.datapesan == 'data diketemukan') {
                            this.datamaster = [];
                            this.datamaster = Response.data.content.data;
                            this.jumlahdata = Response.data.content.jumlahdata;
                            this.jumlahhalaman = Response.data.content.jumlahhalaman;
                            this.mulai = Response.data.content.mulai;
                            if (this.mulai == 0) {
                                this.halaman = 1;
                            }
                            else {
                                this.halaman = (this.mulai / 20) + 1;
                            }
                            this.halamanloading = false;
                        }
                        else {
                            this.halamanloading = false;
                        }

                    }
                ).catch(error => {
                    console.log(error);
                    this.datadetail = [];
                    this.jumlahdata = 0;
                    this.jumlahhalaman = 0;
                    this.mulai = 0;
                    this.halaman = 1;
                    this.halamanloading = false;
                });
        },
        prosesdata(kode) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kode, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/ProsesRumah/" + bytes);
        },
        async AmbilDataDesa(kodekecamatan) {
            this.halamanloading = true;
            var mainAPIDataReff = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataReff.get("parastapainnovationLokasi-GetDesa?random=" + random + "&KodeKabkot=" + 3373 + "&KodeKecamatan=" + kodekecamatan).then(
                Response => {
                    this.datakelurahan = [];
                    this.kelurahan = [];
                    this.datakelurahan = Response.data.content.data;
                    this.datakelurahan.forEach((item) => {
                        this.kelurahan.push({
                            label: item.Nama,
                            code: this.datakelurahan.indexOf(item) + 1,
                            tag: item.Kode,
                        });
                    });
                    this.kelurahan.unshift({
                        label: 'Semua Kelurahan',
                        code: 0,
                        tag: '',
                    });
                    this.defaultSelectedkelurahan = {
                        label: 'Semua Kelurahan',
                        code: 0,
                        tag: '',
                    };
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        async AmbilDataReff() {
            this.halamanloading = true;
            var mainAPIDataReff = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataReff.get("parastapainnovation-GetM_Verifikasi?random=" + random).then(
                Response => {
                    this.dataverifikasi = [];
                    this.verifikasi = [];
                    this.dataverifikasi = Response.data.content.data;
                    this.dataverifikasi.forEach((item) => {
                        this.verifikasi.push({
                            label: item.nama,
                            code: this.dataverifikasi.indexOf(item) + 1,
                            tag: item.id,
                        });
                    });
                    this.verifikasi.unshift({
                        label: 'Pilih Data',
                        code: 0,
                        tag: '',
                    });
                    //this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            await mainAPIDataReff.get("parastapainnovation-GetM_Tipe?random=" + random).then(
                Response => {
                    this.datatipe = [];
                    this.tipe = [];
                    this.datatipe = Response.data.content.data;
                    this.datatipe.forEach((item) => {
                        this.tipe.push({
                            label: item.nama,
                            code: this.datatipe.indexOf(item) + 1,
                            tag: item.id,
                        });
                    });
                    this.tipe.unshift({
                        label: 'Pilih Data',
                        code: 0,
                        tag: '',
                    });
                    //this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            await mainAPIDataReff.get("parastapainnovationLokasi-GetKecamatan?random=" + random + "&KodeKabkot=" + 3373).then(
                Response => {
                    this.datakecamatan = [];
                    this.kecamatan = [];
                    this.datakecamatan = Response.data.content.data;
                    this.datakecamatan.forEach((item) => {
                        this.kecamatan.push({
                            label: item.Nama,
                            code: this.datakecamatan.indexOf(item) + 1,
                            tag: item.Kode,
                        });
                    });
                    this.kecamatan.unshift({
                        label: 'Semua Kecamatan',
                        code: 0,
                        tag: '',
                    });
                    //this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            await mainAPIDataReff.get("parastapainnovationLokasi-GetDesa?random=" + random + "&KodeKabkot=" + 3373).then(
                Response => {
                    this.datakelurahan = [];
                    this.kelurahan = [];
                    this.datakelurahan = Response.data.content.data;
                    this.datakelurahan.forEach((item) => {
                        this.kelurahan.push({
                            label: item.Nama,
                            code: this.datakelurahan.indexOf(item) + 1,
                            tag: item.Kode,
                        });
                    });
                    this.kelurahan.unshift({
                        label: 'Semua Kelurahan',
                        code: 0,
                        tag: '',
                    });
                    //this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

        },
        hapus(kode) {
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Hendak Hapus Data?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#2176e6",
                cancelButtonColor: "#ffaa05",
                confirmButtonText: "Hapus"
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = { kode: kode, deletedby: JSON.parse(localStorage.getItem("usistem")) }
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    });
                    mainAPI.post("parastapainnovation_rumah-DeleteRumah", data).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                swal.fire('Informasi', 'Data telah dihapus..', 'success').then(function () {
                                    window.location.replace("/Rumah");
                                });
                            }
                        }
                    ).catch(
                        error => {
                            swal.fire('Peringatan', 'Terjadi Kesalahan Format Data', 'error');
                        }
                    )

                }
            });
            return false;
        },
        async uploadsavedata() {
            var tempheader = this.tempheader;
            //alert(this.tempdata.length);
            //return false;
            for (let i = 0; i < this.tempdata.length; i++) {
                if (i == 0) {
                    var acuan = this.tempdata[i];
                }
                else {
                    var data = "";
                    for (let x = 0; x < this.tempdata[i].length; x++) {
                        if (x == 0) {
                            data = this.tempdata[i][x];
                        }
                        else {
                            data = data + '|' + this.tempdata[i][x];
                        }
                    }
                    var fd = new FormData();
                    fd.append("tempheader", tempheader);
                    fd.append("acuan", acuan);
                    fd.append("data", data);
                    fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            "Content-Type": "form-data",
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                        },
                    });
                    this.halamanloading = true;
                    await mainAPI.post("parastapainnovation_rumah-SaveExcel", fd).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                this.halamanloading = false;
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                this.halamanloading = false;
                            }
                        }
                    ).catch(function (error) {
                        this.halamanloading = false;
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: error
                        });
                    }
                    )
                }
                //console.log(JSON.stringify(tempheader));
                //console.log(JSON.stringify(acuan));
                //console.log(JSON.stringify(data));
                //return false;
            }
            //alert(tempheader);
            //alert(acuan);
            //alert(data);
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.AmbilDataReff();
        this.ambildata();
    }
}
</script>
<style></style>