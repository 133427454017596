import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Beranda',
    component: () => import('../../components/views/Publik/Beranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../../components/views/Publik/Login.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KontakKami',
    name: 'KontakKami',
    component: () => import('../../components/views/Publik/KontakKami.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: () => import('../../components/views/Dashboard/Dashboard.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Regulasi',
    name: 'Regulasi',
    component: () => import('../../components/views/Backoffice/Regulasi/Regulasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesRegulasi/:id',
    name: 'ProsesRegulasi',
    component: () => import('../../components/views/Backoffice/Regulasi/ProsesRegulasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarRegulasi',
    name: 'DaftarRegulasi',
    component: () => import('../../components/views/Publik/DaftarRegulasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Slider',
    name: 'Slider',
    component: () => import('../../components/views/Backoffice/Slider/Slider.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesSlider/:id',
    name: 'ProsesSlider',
    component: () => import('../../components/views/Backoffice/Slider/ProsesSlider.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Kategori',
    name: 'Kategori',
    component: () => import('../../components/views/Backoffice/Kategori/Kategori.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesKategori/:id',
    name: 'ProsesKategori',
    component: () => import('../../components/views/Backoffice/Kategori/ProsesKategori.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/SubKategori',
    name: 'SubKategori',
    component: () => import('../../components/views/Backoffice/SubKategori/SubKategori.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesSubKategori/:id',
    name: 'ProsesSubKategori',
    component: () => import('../../components/views/Backoffice/SubKategori/ProsesSubKategori.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/UnitKategori',
    name: 'UnitKategori',
    component: () => import('../../components/views/Backoffice/UnitKategori/UnitKategori.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesUnitKategori/:id',
    name: 'ProsesUnitKategori',
    component: () => import('../../components/views/Backoffice/UnitKategori/ProsesUnitKategori.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Rumah',
    name: 'Rumah',
    component: () => import('../../components/views/Backoffice/Rumah/Rumah.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesRumah/:id',
    name: 'ProsesRumah',
    component: () => import('../../components/views/Backoffice/Rumah/ProsesRumah.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InfoBeranda',
    name: 'InfoBeranda',
    component: () => import('../../components/views/Backoffice/InfoBeranda/InfoBeranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesInfoBeranda/:id',
    name: 'ProsesInfoBeranda',
    component: () => import('../../components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/User',
    name: 'User',
    component: () => import('../../components/views/Backoffice/User/User.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesUser/:id',
    name: 'ProsesUser',
    component: () => import('../../components/views/Backoffice/User/ProsesUser.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KategoriData',
    name: 'KategoriData',
    component: () => import('../../components/views/Backoffice/KategoriData/KategoriData.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesKategoriData/:id',
    name: 'ProsesKategoriData',
    component: () => import('../../components/views/Backoffice/KategoriData/ProsesKategoriData.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/LayerPeta',
    name: 'LayerPeta',
    component: () => import('../../components/views/Backoffice/LayerPeta/LayerPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesLayerPeta/:id',
    name: 'ProsesLayerPeta',
    component: () => import('../../components/views/Backoffice/LayerPeta/ProsesLayerPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/GIS/:id',
    name: 'GIS',
    component: () => import('../../components/views/Publik/GIS.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KK',
    name: 'KK',
    component: () => import('../../components/views/Backoffice/KK/KK.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesKK/:id',
    name: 'ProsesKK',
    component: () => import('../../components/views/Backoffice/KK/ProsesKK.vue'),
    meta: {
      transition: "zoom",
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes: routes,
  linkActiveClass: 'active',
})

export default router;
