<template>
  <div class="content" v-if="route.name == 'Beranda'">
    <Beranda/>
  </div>
  <div class="content" v-if="route.name == 'Login'">
    <Login/>
  </div>
  <div class="content" v-if="route.name == 'KontakKami'">
    <KontakKami/>
  </div>
  <div class="content" v-if="route.name == 'Dashboard'">
    <Dashboard/>
  </div>
  <div class="content" v-if="route.name == 'Slider'">
    <Slider/>
  </div>
  <div class="content" v-if="route.name == 'ProsesSlider'">
    <ProsesSlider/>
  </div>
  <div class="content" v-if="route.name == 'Kategori'">
    <Kategori/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKategori'">
    <ProsesKategori/>
  </div>
  <div class="content" v-if="route.name == 'SubKategori'">
    <SubKategori/>
  </div>
  <div class="content" v-if="route.name == 'ProsesSubKategori'">
    <ProsesSubKategori/>
  </div>
  <div class="content" v-if="route.name == 'UnitKategori'">
    <UnitKategori/>
  </div>
  <div class="content" v-if="route.name == 'ProsesUnitKategori'">
    <ProsesUnitKategori/>
  </div>
  <div class="content" v-if="route.name == 'Rumah'">
    <Rumah/>
  </div>
  <div class="content" v-if="route.name == 'ProsesRumah'">
    <ProsesRumah/>
  </div>
  <div class="content" v-if="route.name == 'InfoBeranda'">
    <InfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'ProsesInfoBeranda'">
    <ProsesInfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'User'">
    <User/>
  </div>
  <div class="content" v-if="route.name == 'ProsesUser'">
    <ProsesUser/>
  </div>
  <div class="content" v-if="route.name == 'Regulasi'">
    <Regulasi/>
  </div>
  <div class="content" v-if="route.name == 'ProsesRegulasi'">
    <ProsesRegulasi/>
  </div>
  <div class="content" v-if="route.name == 'DaftarRegulasi'">
    <DaftarRegulasi/>
  </div>
  <div class="content" v-if="route.name == 'KategoriData'">
    <KategoriData/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKategoriData'">
    <ProsesKategoriData/>
  </div>
  <div class="content" v-if="route.name == 'LayerPeta'">
    <LayerPeta/>
  </div>
  <div class="content" v-if="route.name == 'ProsesLayerPeta'">
    <ProsesLayerPeta/>
  </div>
  <div class="content" v-if="route.name == 'GIS'">
    <GIS/>
  </div>
  <div class="content" v-if="route.name == 'KK'">
    <KK/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKK'">
    <ProsesKK/>
  </div>
</template>

<script>
import Beranda from './components/views/Publik/Beranda.vue';
import Login from './components/views/Publik/Login.vue';
import KontakKami from './components/views/Publik/KontakKami.vue';
import Dashboard from './components/views/Dashboard/Dashboard.vue';
import Slider from './components/views/Backoffice/Slider/Slider.vue';
import ProsesSlider from './components/views/Backoffice/Slider/ProsesSlider.vue';
import Kategori from './components/views/Backoffice/Kategori/Kategori.vue';
import ProsesKategori from './components/views/Backoffice/Kategori/ProsesKategori.vue';
import SubKategori from './components/views/Backoffice/SubKategori/SubKategori.vue';
import ProsesSubKategori from './components/views/Backoffice/SubKategori/ProsesSubKategori.vue';
import UnitKategori from './components/views/Backoffice/UnitKategori/UnitKategori.vue';
import ProsesUnitKategori from './components/views/Backoffice/UnitKategori/ProsesUnitKategori.vue';
import Rumah from './components/views/Backoffice/Rumah/Rumah.vue';
import ProsesRumah from './components/views/Backoffice/Rumah/ProsesRumah.vue';
import InfoBeranda from './components/views/Backoffice/InfoBeranda/InfoBeranda.vue';
import ProsesInfoBeranda from './components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue';
import User from './components/views/Backoffice/User/User.vue';
import ProsesUser from './components/views/Backoffice/User/ProsesUser.vue';
import Regulasi from './components/views/Backoffice/Regulasi/Regulasi.vue';
import ProsesRegulasi from './components/views/Backoffice/Regulasi/ProsesRegulasi.vue';
import DaftarRegulasi from './components/views/Publik/DaftarRegulasi.vue';
import KategoriData from './components/views/Backoffice/KategoriData/KategoriData.vue';
import ProsesKategoriData from './components/views/Backoffice/KategoriData/ProsesKategoriData.vue';
import LayerPeta from './components/views/Backoffice/LayerPeta/LayerPeta.vue';
import ProsesLayerPeta from './components/views/Backoffice/LayerPeta/ProsesLayerPeta.vue';
import KK from './components/views/Backoffice/KK/KK.vue';
import ProsesKK from './components/views/Backoffice/KK/ProsesKK.vue';
import GIS from './components/views/Publik/GIS.vue';

import { useRoute } from 'vue-router';
import { watch } from 'vue';

export default {
  name: 'App',
  components: {
    Beranda,Login,KontakKami,Dashboard,GIS
    ,Slider,ProsesSlider,InfoBeranda,ProsesInfoBeranda
    ,User,ProsesUser,KategoriData,ProsesKategoriData
    ,Regulasi,ProsesRegulasi,DaftarRegulasi,Kategori,ProsesKategori
    ,SubKategori,ProsesSubKategori,UnitKategori,ProsesUnitKategori
    ,Rumah,ProsesRumah,LayerPeta,ProsesLayerPeta,KK,ProsesKK
  },
  mounted() {
    document.title = "Sirumah";
  },
  setup() {
    const route = useRoute()
    watch(() => route.name, () => {
    });

    return { route };
  }
}
</script>

<style></style>
