<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <center>
            <div class="modal fade" id="costumModaldisclaimer" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
                aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
                style="margin: auto;">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Lampiran</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-justify">
                            <p>
                            <div class="col-sm-12">
                                <iframe src="" id="iframefile" style="width: 100%;height: 55vh;"></iframe>
                            </div>
                            </p>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-danger btn-sm lebar" data-dismiss="modal"><i
                                    class="fas fa-window-close"></i> Tutup</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- /.modal -->
        </center>
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Pengaturan</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/Rumah">Rumah</a></li>
                                <li class="breadcrumb-item active">Pengaturan Rumah</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih2">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Form Input Rumah</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Tahun Data<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="tahun"
                                                    name="tahun" placeholder="Tahun Data" v-model="tahun">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>No KK <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="kk"
                                                    name="kk" placeholder="No KK" v-model="kk">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>No KTP <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="ktp"
                                                    name="ktp" placeholder="No KTP" v-model="ktp">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nama Kepala Keluarga <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="nama"
                                                    name="nama" placeholder="Nama Kepala Keluarga" v-model="nama">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Jenis Kelamin <small class="text-info"> (*wajib
                                                    dipilih)</small></label>
                                            <v-select :options="kelamin" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedkelamin" @update:modelValue="onkelaminChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Usia<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="usia"
                                                    name="usia" placeholder="Usia" v-model="usia">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Pendidikan<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="pendidikan"
                                                    name="pendidikan" placeholder="Pendidikan" v-model="pendidikan">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Alamat<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="alamat"
                                                    name="alamat" placeholder="Alamat" v-model="alamat">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>RT<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="rt"
                                                    name="rt" placeholder="RT" v-model="rt">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>RW<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="rw"
                                                    name="rw" placeholder="RW" v-model="rw">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Kecamatan <small class="text-info"> (*wajib
                                                    dipilih)</small></label>
                                            <v-select :options="kecamatan" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedkecamatan"
                                                @update:modelValue="onkecamatanChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Kelurahan <small class="text-info"> (*wajib
                                                    dipilih)</small></label>
                                            <v-select :options="kelurahan" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedkelurahan"
                                                @update:modelValue="onkelurahanChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                        <div class="col-sm-6">
                                            <label>Jumlah KK<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="jumlahkk"
                                                    name="jumlahkk" placeholder="Jumlah KK" v-model="jumlahkk">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Pekerjaan<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="pekerjaan"
                                                    name="pekerjaan" placeholder="Pekerjaan" v-model="pekerjaan">
                                            </div>
                                        </div>
                                        <div class="col-sm-12" v-for="(datakelengkapan, urutkelengkapan) in kelengkapan"
                                            :key="urutkelengkapan">
                                            <label class="text-primary">{{ (urutkelengkapan + 1) }} . {{
                                                datakelengkapan.nama }}</label>
                                            <div class="row" v-for="(datasub, urutdatasub) in datakelengkapan.sub"
                                                :key="urutdatasub">
                                                <div class="col-sm-5">
                                                    <label>{{ (urutkelengkapan + 1) }} . {{ (urutdatasub + 1) }} . {{
                                                        datasub.nama }}</label>
                                                </div>
                                                <div class="col-sm-7" v-if="datasub.jenis == 1">
                                                    <div class="col-sm-12">
                                                        <div class="form-group" v-if="secretencData != 'Tambah'"
                                                            v-for="(dataunit, urutdataunit) in datasub.unit"
                                                            :key="urutdataunit">
                                                            <div class="form-check">
                                                                <div v-for="(datanameisian, urutdatanameisian) in nameisian"
                                                                    :key="urutdatanameisian">
                                                                    <input class="form-check-input" type="radio"
                                                                        v-if="datanameisian == 'radio' + datasub.id"
                                                                        :name="'radio' + datasub.id"
                                                                        :value="datasub.idunit[urutdataunit]"
                                                                        :checked="(datasub.idunit[urutdataunit] == isian[urutdatanameisian])">
                                                                    <label class="form-check-label"
                                                                        v-if="datanameisian == 'radio' + datasub.id">{{
                                                                            dataunit }} </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else v-for="(dataunit, urutdataunittambah) in datasub.unit" :key="urutdataunittambah">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    :name="'radio' + datasub.id"
                                                                    :value="datasub.idunit[urutdataunittambah]"
                                                                    :checked="(datasub.idunit[urutdataunittambah] == isian[urutdatanameisian])">
                                                                <label class="form-check-label">{{ dataunit }} </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-7" v-else>
                                                    <div v-if="secretencData != 'Tambah'"
                                                        v-for="(datanameisian, urutdatanameisian) in nameisian"
                                                        :key="urutdatanameisian">
                                                        <input type="text" class="form-control form-control-sm"
                                                            v-if="datanameisian == 'text' + datasub.id"
                                                            :id="'text' + datasub.id" :name="'text' + datasub.id"
                                                            :placeholder="datasub.nama"
                                                            :value="isian[urutdatanameisian]">
                                                    </div>
                                                    <div v-else>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'text' + datasub.id" :name="'text' + datasub.id"
                                                            :placeholder="datasub.nama"
                                                            :value="isian[urutdatanameisian]">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Longitude<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="longitude"
                                                    name="longitude" placeholder="Longitude" v-model="longitude">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Latitude<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="latitude"
                                                    name="latitude" placeholder="Latitude" v-model="latitude">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Tipe Rumah <small class="text-info"> (*wajib
                                                    dipilih)</small></label>
                                            <v-select :options="tipe" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedtipe" @update:modelValue="ontipeChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Verifikasi <small class="text-info"> (*wajib
                                                    dipilih)</small></label>
                                            <v-select :options="verifikasi" :reduce="(label) => label.code"
                                                label="label" v-model="defaultSelectedverifikasi"
                                                @update:modelValue="onverifikasiChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                        <!--IKON PETA 1-->
                                        <div class="col-sm-12">
                                            <label>Gambar Rumah 1<small class="text-info"> (*jpg)</small>
                                            </label>
                                            <label v-if="(filenya_rumah != '') && (filenya_rumah != null)"
                                                style="float: right;"> <a href="javascript:void(0)"
                                                    @click="lihatlampiran(filenya_rumah, 'parastapainnovation_rumah-Gambar')"><i
                                                        class="fas fa-file-image"></i> Lihat
                                                    Gambar</a></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="far fa-file-image"></i></span>
                                                </div>
                                                <input type="file" class="form-control form-control-sm"
                                                    id="fileToUploadfilenya_rumah" name="fileToUploadfilenya_rumah"
                                                    placeholder="fileToUpload" style="font-size: smaller;"
                                                    accept="image/*">
                                                <input type="hidden" class="form-control form-control-sm"
                                                    id="filenya_rumah" name="filenya_rumah" placeholder="File Rumah">
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Opsi</label>
                                            <div class="row">
                                                <div class="col-sm-9">
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" aria-valuenow="0"
                                                            aria-valuemin="0" aria-valuemax="100" style="width:0%"
                                                            id="persennyafilenya_rumah">
                                                            <span id="tulisanpersenfilenya_rumah">0%</span>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <span class="text-success" id="suksesnyafilenya_rumah"></span>
                                                </div>
                                                <div class="col-sm-3 text-center">
                                                    <button type="button"
                                                        class="btn btn-warning btn-sm lebar text-white"
                                                        @click="uploadfile('filenya_rumah', 'image/*')">Upload
                                                        File</button>
                                                    <button type="button" class="btn btn-danger btn-sm lebar"
                                                        @click="clearfilepeta('filenya_rumah')">Hapus File</button>
                                                </div>
                                            </div>
                                        </div>
                                        <!--IKON PETA 1 END-->
                                        <!--IKON PETA 2-->
                                        <div class="col-sm-12">
                                            <label>Gambar Rumah 2<small class="text-info"> (*jpg)</small>
                                            </label>
                                            <label v-if="(filenya_rumah2 != '') && (filenya_rumah2 != null)"
                                                style="float: right;"> <a href="javascript:void(0)"
                                                    @click="lihatlampiran(filenya_rumah2, 'parastapainnovation_rumah-Gambar')"><i
                                                        class="fas fa-file-image"></i> Lihat
                                                    Gambar</a></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="far fa-file-image"></i></span>
                                                </div>
                                                <input type="file" class="form-control form-control-sm"
                                                    id="fileToUploadfilenya_rumah2" name="fileToUploadfilenya_rumah2"
                                                    placeholder="fileToUpload" style="font-size: smaller;"
                                                    accept="image/*">
                                                <input type="hidden" class="form-control form-control-sm"
                                                    id="filenya_rumah2" name="filenya_rumah2" placeholder="File Rumah">
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Opsi</label>
                                            <div class="row">
                                                <div class="col-sm-9">
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" aria-valuenow="0"
                                                            aria-valuemin="0" aria-valuemax="100" style="width:0%"
                                                            id="persennyafilenya_rumah2">
                                                            <span id="tulisanpersenfilenya_rumah2">0%</span>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <span class="text-success" id="suksesnyafilenya_rumah2"></span>
                                                </div>
                                                <div class="col-sm-3 text-center">
                                                    <button type="button"
                                                        class="btn btn-warning btn-sm lebar text-white"
                                                        @click="uploadfile('filenya_rumah2', 'image/*')">Upload
                                                        File</button>
                                                    <button type="button" class="btn btn-danger btn-sm lebar"
                                                        @click="clearfilepeta('filenya_rumah2')">Hapus File</button>
                                                </div>
                                            </div>
                                        </div>
                                        <!--IKON PETA 2 END-->
                                        <div class="col-sm-12">&nbsp;</div>
                                        <div class="col-sm-12">
                                            <label>Keterangan</label>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <quill-editor v-model:value="keterangan"
                                                        style="height: 25vh;"></quill-editor>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                        <div class="col-sm-12">&nbsp;</div>
                                    </div>
                                </div>
                                <div class="card-footer p-3">
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        @click="simpan()" v-if="(secretencData == 'Tambah')" data-toggle="tooltip"
                                        data-placement="left" title="Simpan data.."><i class="fas fa-plus-square"></i>
                                        Simpan</button>
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        v-if="(secretencData != 'Tambah')" @click="update()" data-toggle="tooltip"
                                        data-placement="left" title="Update data.."><i class="fa fa-check-circle"></i>
                                        Update</button>
                                    <button type="button" class="btn btn-sm btn-danger3 float-left text-white lebar2"
                                        @click="batalproses()" data-toggle="tooltip" data-placement="left"
                                        title="Batalkan proses.."><i class="fas fa-window-close"></i> Batal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import { quillEditor } from 'vue3-quill';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal, quillEditor
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            nama: '',
            filenya_rumah: '',
            filenya_rumah2: '',
            keterangan: '',
            tahun: '',
            kk: '',
            ktp: '',
            usia: 0,
            pendidikan: '',
            kelamin: [],
            datakelamin: [],
            defaultSelectedkelamin: {
                code: 0,
                label: 'Pilih Data',
                tag: '',
            },
            selectedkelamin: 0,
            kecamatan: [],
            datakecamatan: [],
            defaultSelectedkecamatan: {
                code: 0,
                label: 'Pilih Data',
                tag: '',
            },
            selectedkecamatan: 0,
            kelurahan: [],
            datakelurahan: [],
            defaultSelectedkelurahan: {
                code: 0,
                label: 'Pilih Data',
                tag: '',
            },
            selectedkelurahan: 0,
            alamat: '',
            rw: '',
            rt: '',
            jumlahkk: 0,
            pekerjaan: '',
            longitude: 0,
            latitude: 0,
            verifikasi: [],
            dataverifikasi: [],
            defaultSelectedverifikasi: {
                code: 0,
                label: 'Pilih Data',
                tag: '',
            },
            selectedverifikasi: 0,
            tipe: [],
            datatipe: [],
            defaultSelectedtipe: {
                code: 0,
                label: 'Pilih Data',
                tag: '',
            },
            kelengkapan: [],
            selectedtipe: 0,
            validasi: false,
            idisian: [],
            isian: [],
            nameisian: [],
        }
    },
    methods: {
        lihatlampiran(filenya, alamat) {
            this.halamanloading = true;
            document.getElementById('iframefile').src = "";
            //alert(process.env.VUE_APP_URL_API + foldernya + '?f=' + filenya);
            document.getElementById('iframefile').src = process.env.VUE_APP_URL_API + alamat + '?f=' + filenya;
            $('#costumModaldisclaimer').modal('show');
            this.halamanloading = false;
        },
        onverifikasiChange(a) {
            try {
                this.selectedverifikasi = this.verifikasi[a].tag;
            }
            catch (err) {
                return;
            }
        },
        ontipeChange(a) {
            try {
                this.selectedtipe = this.tipe[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onkelaminChange(a) {
            try {
                this.selectedkelamin = this.kelamin[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onkecamatanChange(a) {
            try {
                this.selectedkecamatan = this.kecamatan[a].tag;
            }
            catch (err) {
                return;
            }
            this.AmbilDataDesa(this.selectedkecamatan);
        },
        onkelurahanChange(a) {
            try {
                this.selectedkelurahan = this.kelurahan[a].tag;
            }
            catch (err) {
                return;
            }
        },
        initialize() {
            //Initialize Select2 Elements
            $(".select2").select2();

            //Initialize Select2 Elements
            $(".select2bs4").select2({
                theme: "bootstrap4",
            });
            $('[data-mask]').inputmask();
            //$('#summernote').summernote();
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        ontabelChange(a) {
            try {
                this.selectedtabel = this.tabel[a].tag;
            }
            catch (err) {
                return;
            }
        },
        batalproses() {
            window.location.replace("/Rumah");
        },
        uploadfile(idnya, tipenya) {
            var elmnt = document.getElementById("fileToUpload" + idnya);
            try {
                var namanya = elmnt.files[0].name;
                //alert(elmnt.files[0]['type'].toUpperCase());
                if (tipenya == 'image/*') {
                    if ((elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPEG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/PNG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/GIF')) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format gambar';
                        return;
                    }
                }
                else {
                    if (elmnt.files[0]['type'] != tipenya) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format pdf';
                        return;
                    }
                }
            } catch (error) {
                document.getElementById('suksesnya' + idnya).innerHTML = '*pilih file terlebih dahulu';
                return;
            }
            var fd = new FormData();
            fd.append("fileToUpload", elmnt.files[0], elmnt.files[0].name);
            fd.append("folderToUpload", idnya);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation-FilesUpload", fd, {
                onUploadProgress: function (uploadEvent) {
                    document.getElementById('persennya' + idnya).style.width = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                    document.getElementById('tulisanpersen' + idnya).innerHTML = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                }
            }).then(
                function (res) {
                    document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                    if (res.data.filename != undefined) {
                        document.getElementById(idnya).value = res.data.filename;
                    }
                }
            ).catch(function (e) {
                document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                document.getElementById(idnya).value = '';
                console.log(e);
            }
            )
        },
        clearfilepeta(idnya) {
            if (document.getElementById(idnya).value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'File Tidak Tersedia',
                });
                return false;
            }

            var fd = new FormData();
            fd.append("folderToUpload", idnya);
            fd.append("filenya", document.getElementById(idnya).value);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation-FilesDeleted", fd).then(
                Response => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: Response.data.message,
                    });
                    document.getElementById(idnya).value == '';

                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )

            document.getElementById('fileToUpload' + idnya).value = '';
            document.getElementById(idnya).value = '';
            document.getElementById('persennya' + idnya).style.width = "0%";
            document.getElementById('tulisanpersen' + idnya).innerHTML = "0%";
            document.getElementById('suksesnya' + idnya).innerHTML = '';
            return false;
        },
        async AmbilDataDesa(kodekecamatan) {
            this.halamanloading = true;
            var mainAPIDataReff = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataReff.get("parastapainnovationLokasi-GetDesa?random=" + random + "&KodeKabkot=" + 3373 + "&KodeKecamatan=" + kodekecamatan).then(
                Response => {
                    this.datakelurahan = [];
                    this.kelurahan = [];
                    this.datakelurahan = Response.data.content.data;
                    this.datakelurahan.forEach((item) => {
                        this.kelurahan.push({
                            label: item.Nama,
                            code: this.datakelurahan.indexOf(item) + 1,
                            tag: item.Kode,
                        });
                    });
                    this.kelurahan.unshift({
                        label: 'Pilih Data',
                        code: 0,
                        tag: '',
                    });
                    this.defaultSelectedkelurahan = {
                        label: 'Semua Kelurahan',
                        code: 0,
                        tag: '',
                    };
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        async AmbilDataReff() {
            this.halamanloading = true;
            var mainAPIDataReff = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataReff.get("parastapainnovation-GetM_Verifikasi?random=" + random).then(
                Response => {
                    this.dataverifikasi = [];
                    this.verifikasi = [];
                    this.dataverifikasi = Response.data.content.data;
                    this.dataverifikasi.forEach((item) => {
                        this.verifikasi.push({
                            label: item.nama,
                            code: this.dataverifikasi.indexOf(item) + 1,
                            tag: item.id,
                        });
                    });
                    this.verifikasi.unshift({
                        label: 'Pilih Data',
                        code: 0,
                        tag: '',
                    });
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            await mainAPIDataReff.get("parastapainnovation-GetM_Tipe?random=" + random).then(
                Response => {
                    this.datatipe = [];
                    this.tipe = [];
                    this.datatipe = Response.data.content.data;
                    this.datatipe.forEach((item) => {
                        this.tipe.push({
                            label: item.nama,
                            code: this.datatipe.indexOf(item) + 1,
                            tag: item.id,
                        });
                    });
                    this.tipe.unshift({
                        label: 'Pilih Data',
                        code: 0,
                        tag: '',
                    });
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            await mainAPIDataReff.get("parastapainnovation-GetM_Kelamin?random=" + random).then(
                Response => {
                    this.datakelamin = [];
                    this.kelamin = [];
                    this.datakelamin = Response.data.content.data;
                    this.datakelamin.forEach((item) => {
                        this.kelamin.push({
                            label: item.nama,
                            code: this.datakelamin.indexOf(item) + 1,
                            tag: item.id,
                        });
                    });
                    this.kelamin.unshift({
                        label: 'Pilih Data',
                        code: 0,
                        tag: '',
                    });
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            await mainAPIDataReff.get("parastapainnovationLokasi-GetKecamatan?random=" + random + "&KodeKabkot=" + 3373).then(
                Response => {
                    this.datakecamatan = [];
                    this.kecamatan = [];
                    this.datakecamatan = Response.data.content.data;
                    this.datakecamatan.forEach((item) => {
                        this.kecamatan.push({
                            label: item.Nama,
                            code: this.datakecamatan.indexOf(item) + 1,
                            tag: item.Kode,
                        });
                    });
                    this.kecamatan.unshift({
                        label: 'Pilih Data',
                        code: 0,
                        tag: '',
                    });
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            await mainAPIDataReff.get("parastapainnovationLokasi-GetDesa?random=" + random + "&KodeKabkot=" + 3373).then(
                Response => {
                    this.datakelurahan = [];
                    this.kelurahan = [];
                    this.datakelurahan = Response.data.content.data;
                    this.datakelurahan.forEach((item) => {
                        this.kelurahan.push({
                            label: item.Nama,
                            code: this.datakelurahan.indexOf(item) + 1,
                            tag: item.Kode,
                        });
                    });
                    this.kelurahan.unshift({
                        label: 'Pilih Data',
                        code: 0,
                        tag: '',
                    });
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            await mainAPIDataReff.get("parastapainnovation_rumah-KelengkapanRumah?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.kelengkapan = [];
                        this.kelengkapan = Response.data.content.data;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.kelengkapan = [];
                this.halamanloading = false;
            });
        },
        async AmbilDataSingel() {
            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataSingel.get("parastapainnovation_rumah-GetRumahByID?id=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.tahun = Response.data.content.data[0].tahun;
                    this.nama = Response.data.content.data[0].nama;
                    this.kk = Response.data.content.data[0].kk;
                    this.ktp = Response.data.content.data[0].ktp;
                    this.usia = Response.data.content.data[0].usia;
                    this.usia = Response.data.content.data[0].usia;
                    this.pendidikan = Response.data.content.data[0].pendidikan;
                    this.defaultSelectedkelamin = {
                        code: Response.data.content.data[0].id,
                        label: Response.data.content.data[0].namakelamin,
                        tag: Response.data.content.data[0].id,
                    };
                    this.selectedkelamin = Response.data.content.data[0].kelamin;
                    this.alamat = Response.data.content.data[0].alamat;
                    this.rt = Response.data.content.data[0].rt;
                    this.rw = Response.data.content.data[0].rw;
                    this.defaultSelectedkecamatan = {
                        code: Response.data.content.data[0].kecamatan,
                        label: Response.data.content.data[0].namakecamatan,
                        tag: Response.data.content.data[0].kecamatan,
                    };
                    this.selectedkecamatan = Response.data.content.data[0].kecamatan;
                    this.defaultSelectedkelurahan = {
                        code: Response.data.content.data[0].kelurahan,
                        label: Response.data.content.data[0].namakelurahan,
                        tag: Response.data.content.data[0].kelurahan,
                    };
                    this.selectedkelurahan = Response.data.content.data[0].kelurahan;
                    this.jumlahkk = Response.data.content.data[0].jumlahkk;
                    this.pekerjaan = Response.data.content.data[0].pekerjaan;
                    this.longitude = Response.data.content.data[0].longitude;
                    this.longitude = Response.data.content.data[0].longitude;
                    this.latitude = Response.data.content.data[0].latitude;
                    this.defaultSelectedtipe = {
                        code: Response.data.content.data[0].tipe,
                        label: Response.data.content.data[0].namatipe,
                        tag: Response.data.content.data[0].tipe,
                    };
                    this.selectedtipe = Response.data.content.data[0].tipe;
                    this.defaultSelectedverifikasi = {
                        code: Response.data.content.data[0].verifikasi,
                        label: Response.data.content.data[0].namaverifikasi,
                        tag: Response.data.content.data[0].verifikasi,
                    };

                    this.selectedverifikasi = Response.data.content.data[0].verifikasi;
                    this.filenya_rumah = Response.data.content.data[0].filenya_rumah;
                    document.getElementById('filenya_rumah').value = Response.data.content.data[0].filenya_rumah;
                    this.filenya_rumah2 = Response.data.content.data[0].filenya_rumah2;
                    document.getElementById('filenya_rumah2').value = Response.data.content.data[0].filenya_rumah2;

                    if (Response.data.content.data[0].idisian != null) {
                        this.idisian = Response.data.content.data[0].idisian.split(",");
                    }
                    else {
                        this.idisian = [];
                    }

                    if (Response.data.content.data[0].isian != null) {
                        this.isian = Response.data.content.data[0].isian.split(",");
                    }
                    else {
                        this.isian = [];
                    }

                    if (Response.data.content.data[0].nameisian != null) {
                        this.nameisian = Response.data.content.data[0].nameisian.split(",");
                    }
                    else {
                        this.nameisian = [];
                    }


                    this.halamanloading = false;
                    //alert(this.idisian);
                    //alert(this.isian);
                    //alert(this.nameisian);
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        validasidata() {
            if (document.getElementById('tahun').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('tahun').focus();
                return false;
            }
            if (document.getElementById('ktp').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('ktp').focus();
                return false;
            }
            if (document.getElementById('nama').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('nama').focus();
                return false;
            }
            if ((this.selectedkelamin == '') || (this.selectedkelamin === null) || (this.selectedkelamin === undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih Data..',
                });
                return false;
            }
            if ((this.selectedkecamatan == '') || (this.selectedkecamatan === null) || (this.selectedkecamatan === undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih Data..',
                });
                return false;
            }
            if ((this.selectedkelurahan == '') || (this.selectedkelurahan === null) || (this.selectedkelurahan === undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih Data..',
                });
                return false;
            }
            if ((this.selectedtipe == '') || (this.selectedtipe === null) || (this.selectedtipe === undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih Data..',
                });
                return false;
            }
            if ((this.selectedverifikasi == '') || (this.selectedverifikasi === null) || (this.selectedverifikasi === undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih Data..',
                });
                return false;
            }
            this.validasi = true;
            return;
        },
        simpan() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            this.idisian = [];
            this.isian = [];
            this.nameisian = [];
            for (let i = 0; i < this.kelengkapan.length; i++) {
                for (let x = 0; x < this.kelengkapan[i].sub.length; x++) {
                    this.idisian.push(this.kelengkapan[i].sub[x].id);
                    if (this.kelengkapan[i].sub[x].jenis == 1) {
                        //alert($("input[type='radio'][name='"+'radio'+this.kelengkapan[i].sub[x].id+"']:checked").val());
                        this.isian.push($("input[type='radio'][name='" + 'radio' + this.kelengkapan[i].sub[x].id + "']:checked").val());
                        this.nameisian.push('radio' + this.kelengkapan[i].sub[x].id);
                    }
                    else {
                        //alert(document.getElementById('text'+this.kelengkapan[i].sub[x].id).value);
                        this.isian.push(document.getElementById('text' + this.kelengkapan[i].sub[x].id).value);
                        this.nameisian.push('text' + this.kelengkapan[i].sub[x].id);
                    }

                }

            }
            var fd = new FormData();
            fd.append("nama", this.nama);
            fd.append("tahun", this.tahun);
            fd.append("kk", this.kk);
            fd.append("ktp", this.ktp);
            fd.append("usia", this.usia);
            fd.append("pendidikan", this.pendidikan);
            fd.append("kelamin", this.selectedkelamin);
            fd.append("alamat", this.alamat);
            fd.append("rw", this.rw);
            fd.append("rt", this.rt);
            fd.append("kecamatan", this.selectedkecamatan);
            fd.append("kelurahan", this.selectedkelurahan);
            fd.append("jumlahkk", this.jumlahkk);
            fd.append("pekerjaan", this.pekerjaan);
            fd.append("longitude", this.longitude);
            fd.append("latitude", this.latitude);
            fd.append("verifikasi", this.selectedverifikasi);
            fd.append("tipe", this.selectedtipe);
            fd.append("keterangan", this.keterangan);
            fd.append("filenya_rumah", document.getElementById('filenya_rumah').value);
            fd.append("filenya_rumah2", document.getElementById('filenya_rumah2').value);
            fd.append("idisian", this.idisian);
            fd.append("isian", this.isian);
            fd.append("nameisian", this.nameisian);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation_rumah-AddRumah", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                            window.location.replace("/Rumah");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
        },
        update() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            this.idisian = [];
            this.isian = [];
            this.nameisian = [];
            for (let i = 0; i < this.kelengkapan.length; i++) {
                for (let x = 0; x < this.kelengkapan[i].sub.length; x++) {
                    this.idisian.push(this.kelengkapan[i].sub[x].id);
                    if (this.kelengkapan[i].sub[x].jenis == 1) {
                        //alert($("input[type='radio'][name='"+'radio'+this.kelengkapan[i].sub[x].id+"']:checked").val());
                        this.isian.push($("input[type='radio'][name='" + 'radio' + this.kelengkapan[i].sub[x].id + "']:checked").val());
                        this.nameisian.push('radio' + this.kelengkapan[i].sub[x].id);
                    }
                    else {
                        //alert(document.getElementById('text'+this.kelengkapan[i].sub[x].id).value);
                        this.isian.push(document.getElementById('text' + this.kelengkapan[i].sub[x].id).value);
                        this.nameisian.push('text' + this.kelengkapan[i].sub[x].id);
                    }

                }

            }
            //alert(JSON.stringify(this.idisian));
            //alert(JSON.stringify(this.isian));
            //return false;

            var fd = new FormData();
            fd.append("nama", this.nama);
            fd.append("tahun", this.tahun);
            fd.append("kk", this.kk);
            fd.append("ktp", this.ktp);
            fd.append("usia", this.usia);
            fd.append("pendidikan", this.pendidikan);
            fd.append("kelamin", this.selectedkelamin);
            fd.append("alamat", this.alamat);
            fd.append("rw", this.rw);
            fd.append("rt", this.rt);
            fd.append("kecamatan", this.selectedkecamatan);
            fd.append("kelurahan", this.selectedkelurahan);
            fd.append("jumlahkk", this.jumlahkk);
            fd.append("pekerjaan", this.pekerjaan);
            fd.append("longitude", this.longitude);
            fd.append("latitude", this.latitude);
            fd.append("verifikasi", this.selectedverifikasi);
            fd.append("tipe", this.selectedtipe);
            fd.append("keterangan", this.keterangan);
            fd.append("filenya_rumah", document.getElementById('filenya_rumah').value);
            fd.append("filenya_rumah2", document.getElementById('filenya_rumah2').value);
            fd.append("idisian", this.idisian);
            fd.append("isian", this.isian);
            fd.append("nameisian", this.nameisian);
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("id", this.secretencData);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation_rumah-UpdateRumah", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah terupdate..', 'success').then(function () {
                            window.location.replace("/Rumah");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.initialize();
        this.AmbilDataReff();
        if (this.secretencData != 'Tambah') {
            this.AmbilDataSingel();
        }
    }
}
</script>
<style></style>